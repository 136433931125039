import React, { useEffect, useState } from 'react';
import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';

function Service() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // toast.success('Vui lòng đợi chút, trong thời gian chúng tôi kết nối với CSKH AeonMall Group!', {
        //     theme: 'light',
        // });

        // setTimeout(() => {
        //     setIsLoading(false);
        // }, 5000);
        window.open('https://livechat123.vip/chat/cskhaeonmall198@gmail.com45107641123300');
    }, []);

    // function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
    //     console.log('LiveChatWidget.onNewEvent', event);
    // }

    return (
        <>
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center">
                    <ReactLoading type="bubbles" color="#ff8a00" height={10} width={70} />
                </div>
            )}

            {/* <LiveChatWidget license="16549317" visibility="maximized" /> */}

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default Service;
